
export default {
name: 'SubMenuBrands',
components: {
  Wrapper: () => import('@/components/Structure/Wrapper')
},
props: {
  content: {
    type: Array,
    default() {}
  }
},
data: () => ({
  brands: {
    a: [],
    b: [],
    c: [],
    d: [],
    e: [],
    f: [],
    g: [],
    h: [],
    i: [],
    j: [],
    k: [],
    l: [],
    m: [],
    n: [],
    o: [],
    p: [],
    q: [],
    r: [],
    s: [],
    t: [],
    u: [],
    v: [],
    w: [],
    x: [],
    y: [],
    z: [],
    num: []
  }
}),
created() {
  for (const item in this.content) {
    if (this.content[item].charAt(0) === 'A') {
      this.brands.a.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'B') {
      this.brands.b.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'C') {
      this.brands.c.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'D') {
      this.brands.d.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'E') {
      this.brands.e.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'F') {
      this.brands.f.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'G') {
      this.brands.g.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'H') {
      this.brands.h.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'I') {
      this.brands.i.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'J') {
      this.brands.j.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'K') {
      this.brands.k.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'L') {
      this.brands.l.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'M') {
      this.brands.m.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'N') {
      this.brands.n.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'O') {
      this.brands.o.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'P') {
      this.brands.p.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'Q') {
      this.brands.q.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'R') {
      this.brands.r.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'S') {
      this.brands.s.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'T') {
      this.brands.t.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'U') {
      this.brands.u.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'V') {
      this.brands.v.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'W') {
      this.brands.w.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'X') {
      this.brands.x.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'Y') {
      this.brands.y.push(this.content[item])
    }
    if (this.content[item].charAt(0) === 'Z') {
      this.brands.z.push(this.content[item])
    }
    if (this.content[item].charAt(0) === '1') {
      this.brands.num.push(this.content[item])
    }
  }
},
methods: {
  format(item) {
    if (item === 'Vision 20/20') {
      return 'vision-2020'
    } else {
      return item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, '')
    }
  }
}
}
